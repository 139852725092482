<template>
    <div>
        <el-select
            :placeholder="placeholder || $t('message.filter')"
            filterable
            multiple
            :size="size"
            class="w-100"
            :value="selected"
            collapse-tags
        >
            <el-option
              v-for="(c, index) in items"
              :key="index"
              :value="c.id" 
              :label="c.name"
            >
                <div class="filter__tasks">
                    <div class="tasks__item">
                        <el-checkbox v-model="c.checked"></el-checkbox>
                    </div>
                    <div :class="c.color ? c.color.name : 'color_for_no_label'" class="tasks__fluid" @click="c.checked ? c.checked = false : c.checked = true">
                        <span>{{ c.name }}</span>
                    </div>
                </div>
            </el-option>
        </el-select>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
  },
  watch: {
    items: {
      handler: function () {
        if(this.labels.length > 0 && this.items.length === 0){
          this.items = JSON.parse(JSON.stringify(this.labels));
        }
        let checked = this.items.filter(item => item.checked === true)
        this.selected = checked.map(item => item.id);
        this.$emit("input", this.selected);
      },
      deep: true,
      immediate: true,
    },
    id: {
      handler: function () {
        this.selected = this.id;
      },
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
      items: []
    };
  },
  mounted() {
    if (this.labels && this.labels.length === 0) this.updateInventory()
        .then(res => {
            this.items = JSON.parse(JSON.stringify(this.labels));
        });
  },
  computed: {
    ...mapGetters({
      labels: "labels/inventory",
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "labels/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
  },
};
</script>
<style scoped>
    .filter__tasks {
        display: flex;
        align-items: center;
    }
    .tasks__item {
        margin-right: 10px;
    }
    .tasks__fluid {
        display: flex;
        justify-content: center;
        width: 180px;
        height: 30px;
        border-radius: 4px;
    }
    .tasks__fluid span {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #fff;
    }
    .color_for_no_label {
        background: #767c85;
    }
</style>
